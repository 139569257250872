import { createI18n } from "vue-i18n";
import { i18n_en } from "./i18n_lang/i18n_en";
import { i18n_fr } from "./i18n_lang/i18n_fr";

const messages = {
  en: i18n_en,
  fr: i18n_fr
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  messages,
});

export default i18n;
