import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:codeArk?",
    redirect: to => ({
      path: '/sign-in/'+to.params.codeArk,
  }),
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in/:codeArk?",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:mail/:accesstoken",
        name: "sign-in-linkedin",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/signaturesToken",
        name: "signaturesToken",
        component: () => import("@/views/still/bord/signatures.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/signatureToken",
    name: "signatureToken",
    component: () => import("@/views/still/signaturesToken.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
 
  {
    path: "/",
    redirect: "/board",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/board",
        name: "board",
        redirect: "/board/overview",
        component: () => import("@/views/still/deploy/deploy.vue"),
        children: [
          {
            path: "overview",
            name: "overview",
            component: () => import("@/views/still/deploy/overview.vue"),
          },
          {
            path: "overview/:search?",
            name: "overviewSearch",
            component: () => import("@/views/still/deploy/overview.vue"),
          },
        ],
      },
      {
        path: "/profil",
        name: "profil",
        component: () => import("@/views/still/Profil.vue"),
      },
      
      {
        path: "/bord",
        name: "bord",
        redirect: "/bord/articles",
        component: () => import("@/views/still/bord/deploy.vue"),
        children: [
          {
            path: "articles",
            name: "articles",
            component: () => import("@/views/still/bord/articles.vue"),
          },
          {
            path: "description",
            name: "description",
            component: () => import("@/views/still/bord/description.vue"),
          },
          {
            path: "identification",
            name: "identification",
            component: () => import("@/views/still/bord/identification.vue"),
          },
          {
            path: "signatures",
            name: "signatures",
            component: () => import("@/views/still/bord/signatures.vue"),
          },
        ],
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/still/admin/admin.vue"),
      },
      {
        path: "/importProd",
        name: "importProd",
        component: () => import("@/views/still/ImportProd.vue"),
      },

    ],
  },

];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


const onTheMinFunc = async () => {
  if( router.currentRoute.value.name == "sign-in" ){
    return false;
  }
  const me = await getAxios("/getMe");
  const xBloque = !me.uti_n_seq || me.uti_x_bloque == true;

  if(xBloque) {
    store.dispatch(Actions.LOGOUT);
    router.push({ name: "sign-in" });
  }

};

setInterval(function() {
  onTheMinFunc();
},1200000);


router.beforeEach(async (to, from, next) => {
  await store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.VERIFY_AUTH);
  setTimeout(() => { window.scrollTo(0, 0); }, 100);

  if (store.getters.isUserAuthenticated){
    //
  }  else{
    //
    //store.dispatch(Actions.LOGOUT);
    //router.push({ name: "sign-in" });
  }
  next();

});

export default router;


export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}