
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import { app } from "./main";
import * as moment from "moment";
import mAxiosApi from "./api";
export let formatDateLang = "YYYY-MM-DD"

export default defineComponent({
  name: "app",
  setup() {
    const i18n = useI18n();
    const frNav = ["fr", "fr-FR","fr-BE", "fr-CA", "fr-LU", "fr-CH", "fr-MC", "fr-MC"];
    let language = getLanguage() as any;
    i18n.locale.value = language;
    const { t } = useI18n();

    function getLanguage(){
      if (localStorage.getItem("language")) {
        return localStorage.getItem("language");
      }else{
        if (frNav.indexOf(navigator.language) !== -1) {
          return "fr";
        }else{
          return "en";
        }
      }
    }

    const tmoment = () => {
      let language = localStorage.getItem("language");
      if (typeof language !== "string") {
        if (frNav.indexOf(navigator.language) !== -1) {
          language = "fr";
        }else{
          language = "en";
        }
      }else{
        if (language === "fr") {
          formatDateLang = "DD.MM.YYYY"
        }else{
          formatDateLang = "YYYY-MM-DD"
        }
      }
      moment.default.locale(language);
    }
    const store = useStore();
    app.config.globalProperties.$t = t;

    async function setLanguageBack(language): Promise<any> {
      const response = await mAxiosApi.get("/setLanguage/" + language);
    }

    onMounted(() => {
      tmoment()
      setLanguageBack(language)
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
export const setFormatDateLang = (format) => {
  formatDateLang = format
}
